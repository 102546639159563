@import 'variables';
@import 'highlight-box';
@import 'mixins';

#lander {
  position:relative;
  width:100%;
  -webkit-perspective: 610px;
  -webkit-perspective-origin: 50% 46%;
  overflow:hidden;
  height: calc(~"100vh - 100px");
  min-height: 800px;
  p,h1,h2 {
    margin:0;
  }
  p {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
  }
  h1 {
    font-size: 88px;
    font-weight: 700;
  }
  h2 {
    font-size: 30px;
  }

  > .lander-main-title {
    color: @brand-yellow;
    position: absolute;
    top: 0;
    font-size: 48px;
    margin-top: 40px;
    animation: title-in 0.4s ease 0s;
    width: 100%;
    text-align: center;
    z-index: 12;
  }

  @media all and (max-width: 1330px) {
    .live-casino-landing & .lander-promo {
      top: 14%;
    }
  }

  @media all and (max-width: 1150px) {
    .live-casino-landing & .lander-promo {
      top: 16%;
    }
  }

  @media all and (max-width: 970px) {
    .live-casino-landing & .lander-promo {
      top: 18%;
    }
  }

}

.lander-section {
  .title {
    color: @brand-yellow;
    margin-top: 60px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  ul {
    list-style: inside;
  }

  .provider-grid {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media all and (min-width: 1050px) {
      flex-direction: row;
    }

  }

  .provider {
    flex-grow: 1;
  }

}

.lander-content {
  color: @brand-white;
  position:relative;
  //position:absolute;
  transform:translate3d(0,0,0);
  width:100%;
  height:100%;
  background:#000;
  //transition: transform 0.3s ease-in;
  > div {
    position:absolute;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size:contain;
  }
  img {
    height: 100%;
  }
  .lander-terms {
    span {
      display: inline-block;
      margin-bottom: 6px;

      @media (max-width: 400px) {
        line-height: 1.3;
        font-size: 13px;
        margin-bottom: 10px;
      }
    }
    a {
      @media (max-width: 400px) {
        margin-bottom: 10px !important;
      }
    }
  }
}

.lander-custom {

  animation: city-in 1s;

  .lander-large-image {
    position: relative;
    width:100%;
    min-height: 170px;
    height: 100%;
    background-size:cover;
    transition: all 0.2s linear;

    @media screen and (min-width: 1100px) {
      min-height: 690px;
    }

    @media screen and (min-width: 1550px) {
      min-height: 900px;
    }

  }

  .lander-main-title {
    font-size: 48px;
    margin-bottom: 60px;
    padding-top: 50px;
    margin-left: 25px;
    position: absolute;
    top: 10px;
    left: 0;
    color: @brand-white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.6);

    @media screen and (min-width: 1100px) {
      margin-left: 44px;
    }

  }

  .highlight-box {
    .highlight-box;

    .uk-compliance-text {
      color: @brand-white;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

#lander-cta {
  width:100%;
  //top:62%;
  z-index:60;
  display:block;

  button {
    display:block;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: var(--button-border-radius);
    margin:0 auto 12px auto !important;
    width: 100%;
    max-width:500px;
    font-weight: 900;
  }
  button.btn-two-rows {
    font-size: 12px;
    @media screen and (min-width: 1151px) {
      font-size: 14px;
    }
  }
  button.btn-two-rows span {
    display: block;
    font-weight: 900;
    font-size: 18px;
    line-height: 25px;
    @media screen and (min-width: 1151px) {
      font-size: 21px;
      line-height: 28px;
    }
  }
}

#cta-b {
  padding: 8px 32px !important;
  background: var(--loginBg-main);
  color: var( --loginTxt-main);
  border: 1px solid --lander-login-border-color;
    &:hover{
      background: var(--loginBG-hover-main);
      transition: 0.2s;
    }
}

#lander-city {
  width: 100%;
  height: 100%;
  left: 0%;
  top:0%;
  z-index:10;
  pointer-events:none;

  &.live-casino {
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

}

#lander-aff {
  width: 100%;
  z-index: 10;
  top:2%;
  text-align: center;
  img {
    max-width:200px;
  }
}

#lander-promo {
  text-align:center;
  width:40%;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 0;
  align-items: center;
  justify-content: center;
  left:33%;
  z-index:60;
  animation: captain-in 0.4s ease 0s;

  a {
    position: relative;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }

  a:hover {
    transform: scale(.95);
  }

  h1 {
    position: absolute;
    font-size: min(5.5vw, 28px);
    top: 11%;
    left: 50%;
    white-space: nowrap;
    transform: translate(-50%);
  }

  img {
    width: 100%;
    max-width: 500px;
    height:auto;
    margin-bottom: 24px;
    transition: all 0.5s ease-in-out;
  }
  @media screen and (min-width: 961px) {
    width: 32%;
    left: 34%;
  }

  @media screen and (min-width: 1150px) {
    h1 {
      font-size: min(1.5vw, 28px);
    }
  }
}

#lander-bottom {
  .bggradient(rgba(0,0,0,0), rgba(0,0,0,1.0));
  bottom: 0;
  text-align: center;
  width: 100%;
  height:200px;
  z-index: 20;
  display: none;
  img {
    width: 193px;
    margin: 46px 16px 16px;
  }
}

#lander-left, #lander-right {
  transition: transform 0.5s ease-in;
  height:100%;
  top:0%;
  z-index:12;
}

#lander-left {
  left:0%;
}

#lander-right {
  right:0;
}

#lander-right-img {
  float:right;
}

.lander-main-button {
  background:@brand-yellow;
  color:black;
}

@media (max-width: 1000px) {
  #lander-right {
    display: none;
  }
}

@media (max-width: 960px) {
  .lander-main-button {
    font-size: 20px;
  }
}

@media (max-width: @menu-switch-breakpoint) {
  #lander {
    min-height:720px;
  }
  #lander-bottom img {
    display:none;
  }
  #lander-promo {
    width:80%;
    left:10%;
    justify-content: unset;
    padding-top: 50px;
  }

  #lander-left, #lander-right {
    display:none;
  }
  #lander-cta {
    width:100%;
    top:58%;
  }
}

@keyframes captain-in {
  from {
    transform: translate3d(0%,-100%,0);
  }
  to {
    transform: translate3d(0%,0%,0);
  }
}

@keyframes title-in {
  from {
    top: -160px;
  }
  to {
    top: 0px;
  }
}

.lander-registration {
  background-image: url('/assets/img/mobile-lander.jpg');
  background-size: cover;
  aspect-ratio: 450 / 630;

  @media (min-width: 500px) {
    background-image: url('/assets/img/desktop-lander.jpg');
    aspect-ratio: 1440 / 384;
  }
}
