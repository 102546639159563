@import "variables";
@import "mobile-nav-icons.less";

.slider-filter {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    overflow-x: hidden;
    transition: .3s;
    transition-timing-function: ease-out;
    transform: translateX(-100%);

    &.open {
        background-color: rgba(0, 0, 0, 0.9);
        transform: translateX(0);
    }
}

.new-mobile-nav {
    .top-bar {
        position: fixed;
        z-index: 9;
        width: 100%;
        top: 0;
        left: 0;
        height: @mobile-header-height;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--secondary-bg-color);
        img {
            margin: 0 auto;

            &.sportsbook-logo-top-image {
                margin: 0;
            }
        }

        .btn-wide {
            width: 28%;
            font-weight: 800;
            font-size: 12px;
            padding: 8px;
            letter-spacing: 0.05em;
            margin: 15px;
        }

        .btn-wide-es {
            width: 28%;
            font-weight: 600;
            font-size: 11px;
            padding: 8px;
            letter-spacing: 0.05em;
            margin: 15px;
        }

        .plus-btn {
            font-weight: bold;
            font-size: 20px;
            padding: 6px 12px 6px 13px;
            margin-right: 15px;
        }

        .deposit-button-mobile {
            width: 35px;
            height: 50px;
            margin: 0;
        }

        .link-wrap {
            
            a {
                display: block;
                color: var(--top-bar-menu-btn-color);
                z-index: 99;
                font-size: 8px;
                cursor: pointer;
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                width: max-content;
                margin: 0;

                i {
                    display: block;
                    margin-bottom: 5px;
                    transition: all 0.2s ease-in-out 0s;
                    font-size: 22px;
                }
                  
            }
        }
    }
    
    .rizk-jewel-reward-container, .dude {
        display: flex;
        justify-content: center;
    }

    .sidenavcontainer {
        width: 100vw;
        height: 100%;
        display: flex;
        z-index: 100;
        top: 0;
        left: 0;
        position: fixed;
        transition: 0.4s;
        transition-timing-function: ease-out;
        transform: translateX(-100%);
        flex-direction: column;
        justify-content: space-between;

        &.open {
            transform: translateX(0);
        }
    }

    .sidenavouter {
        float: right;
        height: 100%;
        position: absolute;
    }

    .sidenav {
        height: 100%;
        width: 85%;
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 99;
        background-color: @side-menu-background-color;
        overflow-x: hidden;
        top: 0;
        left: 0;
    }

    // Top navigation
    .user-name {
        color: var(--primary-color);
        font-size: 12px;
        padding: 0;
        margin-left: 0;
        text-transform: uppercase;
        font-weight: 500;
    }

    .user-level {
        font-weight: 700;
        padding: 0;
    }

    .deposit-amount {
        color: var(--tertiary-color);
        font-size: 17px;
        font-weight: 900;
        letter-spacing: 0.6px;
    }

    // Close button on sidenav
    .close-label {
        position: absolute;
        right: 6px;
        top: 6px;
        cursor: pointer;
        height: 25px;
        width: 25px;
    }

    .close {
        position: absolute;
        right: 0px;
        top: 2px;
        width: 30px;
        transition-duration: .2s;
    }

    .close:before,
    .close:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 21px;
        width: 2px;
        background-color: var(--side-menu-close-btn-color);
    }

    .close:before {
        transform: rotate(45deg);
    }

    .close:after {
        transform: rotate(-45deg);
    }

    // Top-bar - sidenav
    .top-bar-sidenav {
        background-color: var(--secondary-bg-color);

        .sm-buttons {
            padding-bottom: 10px;

            button {
                padding: 11px;
            }
        }
    }

    .sidenav-logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin: 25px 0 10px 0;
        }
    }

    // Bottom-bar - sidenav
    .bottom-bar-sidenav {
        background-color: @side-menu-bottom-bar;
        border: 1px solid #212121;
        box-sizing: border-box;
        width: 85%;
    }

    .chat-item {
        text-align: center;
        margin-bottom: 10px;

        span {
            margin-left: 7px;
            color: @brand-white;
            cursor: pointer;
            letter-spacing: 1px;
            font-size: 16px;
        }

        i {
            color: @brand-white;
        }
    }

    .clock-sidenav {
        color: @insider-description-color;
        font-size: 12px;
        margin: 10px 0;
        display: block;
        text-align: center;
    }

    .lastloginmob {
        color: @insider-description-color;
        font-size: 12px;
        margin: 10px 0;
        display: block;
        text-align: center;

        .lastlogin {
            color: @insider-description-color;
            font-size: 12px;
            margin: 10px 0;
            display: block;
            text-align: center;
        }

    }

    .menu-item {
        color: @brand-white;
        display: flex;
        cursor: pointer;
        width: 90%;
        align-items: center;
        span {
            letter-spacing: 1px;
            font-size: 16px;
            margin-left: 12px;
            line-height: 23px;
        }

        i {
            font-size: 22px;
        }
        &.menu-item-active {
            color: var(--tertiary-color);
          }
    }

    .content-sidenav {
        margin: 20px 0 0 25px;
        height: calc(100vh - 326px);
        display: flex;
        flex-direction: column;
        gap: 40px;
        justify-content: flex-start;

        &__menu-items {
            display: flex;
            flex-direction: column;
            gap: 25px;
            height: max-content;
        }

        .icon-casino {
            margin-left: 2px;
        }
    }

    .active-item {
        color: var(--tertiary-color) !important;
    }

    .badge {
        padding: 2px 4px;
        bottom: 11px;
        margin-left: 5px;
        font-size: 7px;
        letter-spacing: 1px;
        text-transform: uppercase;
        white-space: nowrap;
        background-color: @badge-new;
        border-radius: 2px;
        position: relative;
        font-weight: 600;
        color: @brand-white !important;
    }

    .reward-count {
        background: @brand-red;
        color: @brand-white !important;
        animation: glow 0.5s ease-in 2s 3;
        border-radius: var(--button-border-radius);
        display: none;
        width: 10px; // set a fixed width
        height: 10px; // set a fixed height
        justify-content: center;
        z-index: 1;
        margin-left: 1px !important;
        font-weight: 600;
        align-items: center;
        padding: 0; // remove padding to maintain the circle shape
        font-size: 13px !important;
        transform: translateY(-2px) !important;
    }


    .gray-separator {
        border-bottom: 1px rgba(255, 255, 255, 0.04) solid;
        position: relative;
        left: -25px;
        top: -15px;
        width: 110%;
    }

    // Styles log in
    #log-in {
        .content-sidenav {
            height: calc(100vh - 376px);

            &__menu-items {
                gap: 15px;
            }
        }

        .rizk-logo {
            margin-bottom: 5px
        }

        .top-bar {
            height: @mobile-header-login-height;
            background: var(--top-bar-bg-color);
            position: relative;
            display: flex;
            gap: 20px;
            padding: 15px;
        }

        #level-display-2{
            color: var(--tertiary-color-button);
        }

        .sidenav .top-bar-sidenav {
            .deposit-amount {
                .header-font(28px);
                letter-spacing: 0.2px;
                margin-top: 20px;
            }
        }

        .top-bar-sidenav {
            background: var(--secondary-bg-color);
            align-items: center;
            text-align: center;
            padding: 20px;

            .deposit-btn-yellow {
                .header-font(20px);
                width: 100%;
                padding: 11px 7px;
                color: var(--secondary-color);
                background-color: var(--primary-button-bg);
                margin: 8px 0;
                transition: 0.3s;

                &:hover {
                    background-color: var(--primary-button-bg-hover);
                }
              
              &.ghost-btn {
                border: 1px solid var(--secondary-color);
                background-color: transparent;
              }
            }

            .deposit-amount {
                .header-font(34px);
                color: var(--side-menu-deposit-amt-color);
                margin-right: 0;
            }

            .user-name {
                margin-top: 15px;
                font-size: 12px;
                text-transform: uppercase;
                font-weight: bold;
                letter-spacing: 1.2px;

                div {
                    display: inline;
                }
            }

            .user-level {
                color: rgba(0, 0, 0, 0.4);
                text-transform: uppercase;
                margin: 5px 0 15px 0;
            }

            .rizk-power {
                padding: 0 20px;
            }
        }

        .wrap {
            .dude {
                position: static;
                border-radius: 50%;
                box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.2);
                width: 61px;
                
                //margin: -5px auto;
                &:active {
                    transform: scale(0.95);
                }
            }

            .indicator-container {
                border: none;
                border-radius: 0px;
                height: 6px;
                top: 4px;
            }
        }
    }

    .bonus-amount {
        color: var(--primary-color);
        font-size: 11px;
        margin-left: 3px;
        font-weight: 600;
        width: 100%;

        p {
            display: inline-block;
        }
    }

    .bonus-amount2 {
        color: var(--primary-color);
        margin-left: 3px;
        font-weight: 400;
    }
}

@media (min-width: @sb-tablet) {

    #log-in,
    #topBar,
    #bottom-bar {
        display: none;
    }
}

.fi {
    .new-mobile-nav #log-in {
        .content-sidenav {
            &__menu-items {
                gap: 15px;
            }
        }
    }
}


@media only screen and (min-width: 430px) {

    .new-mobile-nav #log-in,
    .new-mobile-nav {
        gap: 50px;

        .content-sidenav {
            &__menu-items {
                gap: 25px;

                .menu-item span {
                    font-size: 18px;
                }
            }

            a span {
                font-size: 18px;
            }
        }
    }

    .fi {
        .new-mobile-nav #log-in {
            .content-sidenav {
                &__menu-items {
                    gap: 20px;
                }
            }
        }
    }
}

.topMenu {

    &.top-bar{
        padding: 11px 7px!important;
        height: 58px;

        .top-image {
            margin: inherit;
        }
        .link-wrap .icon-menu-top{
            margin-bottom: 2px;
        }
    }

    &--side {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
    }
       
    &-items {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }

    .wallet-wrapper {
        display: flex;
        align-items: center;
        gap: 3px;
        background-color: var(--top-bar-wallet-wrapper-bg);
        padding: 7px 5px;
        border-radius: 5px;

        img.deposit-button-mobile {
            width: 25px;
            height: 25px;
        }

        .deposit-amount {
            color: var(--top-bar-wallet-wrapper-color);
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.6px;
            display: flex;
            flex-direction: column;

            .bonus-amount2 {
                font-weight: 400;
                font-size: 11px;
                margin-left: 0;
                color: #676767;
            }
        }
    }
}
