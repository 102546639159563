@import 'breakpoints';

.container {
  max-width: 100vw;
  margin: 20px 10px 0;

  .tablet({
        max-width: 1080px;
        margin: 20px auto 0;
    });
}

.seo-content-box {
  padding: 40px 20px 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  &--faded {
    max-height: 300px;
    mask-image: linear-gradient(to bottom, var(--secondary-color) 50%, transparent 100%);
  }

  &__arrow {
    background-image: url('/assets/img/arrowdown.svg');
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    line-height: 12px;
  }

  &__show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    cursor: pointer;
    line-height: 12px;
    height: 12px;
    color: var(--secondary-color-button);

    &::before {
      content: attr(data-text);
      padding-right: 7px;
      height: 12px;
      display: flex;
      align-items: center;
    }
  }

  &__input[type='checkbox'] {
    &:checked {
      display: none;

      & ~ .seo-content-box {
        height: inherit;
        max-height: inherit;
        mask-image: none;

        &__show-more {
          .seo-content-box__arrow {
            transform: rotate(180deg);
            margin-bottom: 3px;
          }
          &::before {
            content: attr(data-textless);
          }
        }
      }
    }
  }
}

.captain-santa-hat {
  display: none;
}

.avatar-block--christmas {
  &.avatar-block {
    height: 70px;
  }
  .captain-santa-hat {
    display: block;
    width: 100%;
    height: 30px;
    background-image: url(/assets/img/xmas-hat.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 10px;
    top: 0px;
    z-index: 100;

    .desktop({
        width: 100%;
        height: 40px;
        background-position: center;
        top: -9px;
      });

    & ~ .dude {
      height: 54px;

      img {
        height: 54px;
      }
    }
  }
  .desktop({
    &.avatar-block {
        height: 90px;
    }
    .captain-santa-hat {
      & ~ .dude {
        height: 64px;
  
        img {
          height: 64px;
        }
      }
    }

    });
}
.side-menu-container {
  .sm-collapse-btn {
    background: var(--tertiary-bg-button);
    color: var(--tertiary-color);
    &:hover {
      background: var(--tertiary-bg-button);
    }
  }
}

.side-menu .main-navigation {
  margin-bottom: 30px;

  .secondary-menu a {
    padding: 10px 0 10px 10px;
  }
  a {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;

    &.active,
    &:focus,
    &:hover {
      color: var(--menu-item-color);
      background: var(--menu-item-hover-bg);
    }
    &.menu-item-active {
      color: var(--menu-item-color);
      background: var(--menu-item-active-bg);
    }
  }
  .reward-count {
    background: @brand-red;
    color: @brand-white;
    border-radius: 50%;
    display: none;
    height: 10px;
    min-width: 10px;
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    z-index: 1;
    padding: 0;
    position: absolute;
    top: 8px;
    animation: glow 0.5s ease-in 2s 3;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8);
    .gibson-semi-bold;
  }
}

.inka-logo-progress {
  display: block;
  transform: translate(-17px, -11px) !important;
  object-fit: cover;
}

@media screen and (min-width: 1151px) {
  .inka-logo-progress {
    height: 85px;
  }
}

@media screen and (max-width: 1150px) {
  .dude {
    img:not(.betsson-logo) {
      transform: translate(0, -3px);
    }
  }
}

.inka-logo-progress {
  display: block;
  height: 68px;
  height: 85px !important;
  transform: translate(-17px, -11px) !important;
  object-fit: cover;
}

#root {
  .skeleton-container {
    padding: 5px;
    overflow: hidden;

    @media (min-width: 1000px) {
      padding: 25px;
    }

    .skeleton-header {
      height: 95px;
      background-color: #242323;
      margin-bottom: 10px;

      @media (min-width: 500px) {
        width: 60%;
        height: 60px;
      }
    }

    .skeleton-content {
      display: flex;
      flex-direction: column;
    }

    .skeleton-item {
      height: 150px;
      background-color: #242323;
      border-radius: 4px;
      width: 100%;
      animation: pulse 1.5s infinite ease-in-out;
    }

    .skeleton-category,
    .skeleton-category-header {
      padding: 10px 0;
    }

    .skeleton-category-header {
      .content {
        background-color: #242323;
        height: 25px;
        width: 300px;
      }
    }

    .skeleton-category-row {
      display: flex;
      gap: 10px;
      padding-top: 10px;
    }

    .skeleton-tile {
      width: 130px;
      min-width: 130px;
      aspect-ratio: 1 / 1;
      border-radius: 10px;
      border: 0.2px solid #ffffff54;
      box-shadow: 0 0 12px #ffffff54;
      opacity: 0.5;

      img {
        width: 100%;
      }

      @media (min-width: 1000px) {
        width: 186px;
        min-width: 186px;
      }
    }

    @keyframes pulse {
      0% {
        background-color: #242323;
      }
      50% {
        background-color: #2d2b2b;
      }
      100% {
        background-color: #2d2b2b;
      }
    }
  }
}

// horizontal menu styling
@media screen and (min-width: 1150px) {
  .horizontal-toggle {
    .sm-collapse-btn {
      display: none;
    }
    .all-content {
      top: 120px !important;
      width: 100% !important;
      left: 0;
    }
    .side-menu {
      width: 100%;
      display: grid;
      grid-template-rows: 1fr 1fr;
      height: 120px;
      z-index: 18;
      .user-menu {
        display: grid;
        width: 100%;
        justify-content: space-between;
        grid-template-columns: 0.3fr 0.2fr;
        background-color: var(--secondary-bg-color);
        .menu-logo {
          margin: 0;
          height: 100%;
        }
        .sm-buttons {
          display: flex;
          flex-direction: row-reverse;
          width: 100%;
          margin: 5px;

          a {
            align-content: center;
            margin-bottom: 5px;
            margin-top: 5px;
            margin-right: 5px;
          }
        }
      }
      .main-navigation {
        display: flex;
        width: 100%;
        align-self: center;
        margin: 0;
        background-color: var(--secondary-bg-color);
        text-transform: uppercase;
        overflow-y: hidden;
        .sm-clock {
          display: none;
        }
        .main-menu {
          padding-left: 15px;
          background: none;
          display: flex;
          width: 100%;
          margin: 0;
          align-items: center;
          height: 100%;
          i {
            display: none;
          }
          span {
            min-width: fit-content;
          }
        }
      }
    }
    &.logged {
      .reward-count {
        width: 10px;
      }
      .avatar-block {
        height: auto;
      }
      .user-menu {
        grid-template-columns: 0.4fr 0.6fr;
        .user-information {
          margin-right: 20px;
          justify-content: right;
          display: flex;
          height: 55px;
          cursor: pointer;
          gap: 10px;
          .user-information-main {
            display: flex;
            background: #e05a00;
            border-radius: 5px;
            height: 98%;
            align-self: center;
            margin-left: 5px;
            transition: 0.3s all ease-in-out;
            &:hover {
              scale: 0.98;
            }
            .icon-user-container {
              width: 50px;
              height: 50px;
              display: block;
              background: orange;
              border-radius: 25px;
              left: -2px;
              position: relative;
              transition: all 0.3s ease-in-out;
              .icon-person {
                position: relative;
                display: block;
                height: 100%;
                width: 100%;
                align-content: center;
                text-align: center;
              }
            }
            .user-new-level-container {
              min-width: 210px;
            }
          }
          .dude {
            left: 0;
            height: 45px;
            width: 45px;
            margin: 0;
          }
          .user-basic-info {
            display: flex;
            .user-name,
            .level {
              margin: 5px 0 5px 0;
            }
            .user-name {
              color: white;
            }
          }
          .deposit-section {
            width: 175px;
            background: none;
            align-content: center;
            &:hover {
              .btn-green {
                scale: 1.1;
              }
            }
            .deposit-cta {
              padding: 0;
            }
            button {
              border-radius: 5px;
              height: 100%;
              padding: 11px;
              transition: all 0.3s ease-in-out;
              background: #e05a00;
            }
            .deposit-button-mobile {
              width: 33px;
              margin-left: 8px;
            }
          }
          .withdraw-btn {
            width: 175px;
          }
          .icon-container {
            height: 40px;
            padding: 10px;
            text-align: center;
            align-content: center;
            background: #e05a00;
            border-radius: 30px;
            margin-right: 5px;
            align-self: center;
            transition: all 0.3s ease-in-out;
            &:hover {
              scale: 0.95;
            }
            i {
              display: block;
              font-size: 20px;
              filter: brightness(5);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1149px) {
  .horizontal-toggle {
    &:not(.logged) {
      .all-content {
        margin-top: 105px !important;
      }
      .lobby-top-section,
      #TopBarParent {
        top: 105px !important;
      }
      .top-bar {
        height: 106px !important;
        display: flex;
        width: 100%;
        flex-direction: column;
        .top-bar-menu-trigger {
          display: grid;
          grid-template-columns: 0.1fr 0.8fr;
          width: 100%;
        }
        #menuIcon {
          color: white;
        }
        img {
          height: 30px;
        }
      }
      .top-bar-btns {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        #signUp,
        #logIn {
          display: block !important;
          width: 100%;
          margin: auto;
          height: 37px;
        }

        #signUp {
          order: 1;
        }
      }
    }
  }
}

// end of horizontal menu styling
