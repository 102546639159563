.internal-links-container {
  display: flex;
  flex-wrap: nowrap; /* Prevents wrapping to the next line */
  overflow-x: scroll; /* Enable horizontal scrolling */
  justify-content: flex-start; /* Distribute space evenly between links */
  gap: 10px; /* Add space between links */


  /* Hide scrollbar for webkit-based browsers */
  &::-webkit-scrollbar {
    width: 0px; /* Set scrollbar width to 0 */
    height: 0px; /* Set scrollbar height to 0 */
  }

  /* Hide scrollbar for other browsers */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    overflow-x: scroll; /* Enable horizontal scrolling on mobile */
  }
}



.popLink-row-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.popularLinks__title {
  .header-font(28px);

  margin-bottom: 24px;
  color: var(--primary-color);

  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    .font-size(24px);
  }
}

.popLink-row-header-container a {
  color: var(--tertiary-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  text-transform: capitalize;

  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.show-all-popLinks.custom-button {
  background-color: none !important; /* Override background color */
  border-radius: initial !important; /* Override border radius */
  color: var(--primary-color) !important; /* Override color */
  font-size: 12px !important; /* Override font size */
  font-weight: initial !important; /* Override font weight */
  margin-top: initial !important; /* Override margin top */
  padding: 0 !important; /* Override padding */
  align-items: center; /* Apply .show-all-popLinks styles */
  background: none; /* Apply .show-all-popLinks styles */
  border: none; /* Apply .show-all-popLinks styles */
  display: flex; /* Apply .show-all-popLinks styles */
  letter-spacing: 1px; /* Apply .show-all-popLinks styles */
  text-transform: uppercase; /* Apply .show-all-popLinks styles */
}

.show-all-popLinks.custom-button:hover {
  /* Add your hover styles here */
  background: none !important;
  /* Add any other hover-specific styles */
  text-decoration: underline;
}

.internal-link {
  position: relative;
  height: 250px;
  min-width: 200px;
  width: 200px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media (max-width: 500px) {
    background-size: contain;
  }
  &.gb{
    border-radius: 8px;

    @media (max-width: 500px) {
      height: 200px;
      min-width: 150px;
    }
  }
 
}

.overlay-poplinks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -100;
}

.logo-poplink, .title-poplink {
  z-index: 2;
}

.logo-poplink {
  width: 50px;
  height: auto;
  text-overflow: ellipsis;

  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    width: 40px;
  }
}

.title-poplink {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevent text from wrapping */
  width: 90%;


  /* Mobile-friendly styles */
  @media (max-width: 767px) {
    font-size: 16px;
    display: block; /* Ensure the title is displayed as a block element */
    white-space: normal; /* Allow text to wrap */
  }
}
