label {
  display: inline-block;
  color: #7d7d7d;
  font-weight: 800;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  text-align: left;
  font-size: 12px;
  letter-spacing: @letr-spacing-large;
}

.form-error-header {
  color: red;
}

.form-error {
  color: red;
}

.has-error {
  .help-block {
    min-height: 10px;
    transform: rotateX(0deg);
  }
}

.has-success {
  .help-block {
    transform: rotateX(-90deg);
    transition: all 0.2s ease-in;
  }
}

.form-group {
  position: relative;
  vertical-align: top;
  margin-bottom: 10px;

  .validator-icon {
    top: 28px;
    right: 6px;
    width: 24px;
    height: 24px;
    display: none;
    background: url('../img/form-icons.png');
    background-size: 24px;
    position: absolute;

    transition: all 0.2s ease-in;

    &.glyphicon-ok {
      background-position: 0 0;

      &:before {
        content: '';
      }
    }

    &.glyphicon-remove {
      background-position: 0 -24px;

      &:before {
        content: '';
      }
    }
  }

  &.has-error {
    label {
      color: @brand-red !important;
    }

    input:focus,
    input {
      border-color: @brand-red;
    }
  }

  #form_user_dob {
    height: 100%;
  }

  select {
    margin: 0;
    outline: none;
    border: none;
    font-size: 16px;
    padding: 0 25px 0 10px;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    -moz-appearance: none;
    height: 100%;
  }

  .select-container {
    .select-container;
  }

  .select-wrap,
  input {
    display: inline-block;
    background: transparent;
    width: 100%;
    margin: 0;
    padding: 10px 35px 10px 15px;
    font-size: 16px;
    .rizk-border-radius;
    border: 1px solid #dbdbdb;
    height: 40px;
    position: relative;

    &.no-padding {
      padding: 0;
    }

    &:focus {
      border: 1px solid var(--brand-green);

      & > label {
        color: var(--brand-green);
      }
    }

    &.valid {
      border-color: var(--brand-green);
    }
  }

  .select-wrap {
    padding: 4px;
  }

  .help-block {
    margin-top: 8px;
  }
}

section .form-group {
  &.has-error,
  &.has-success {
    .validator-icon {
      display: block;
    }
  }
}

#rizk-payment-iframe-container .form-group {
  margin-bottom: 0;
}

.form-v2 .form-group select {
  display: inline-block;
  background: transparent;
  width: 100%;
  margin: 0;
  padding: 10px 35px 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  height: 40px;
  position: relative;
}

input,
select {
  -webkit-appearance: none;
}

input[type='checkbox'],
input[type='radio'] {
  display: none !important;

  & + label span {
    background: url('../img/form-icons.png') top center;
    background-size: 24px;
    background-position: 0 -72px;
    display: inline-block;
    width: 24px;
    height: 24px;
    position: relative;
    top: 7px;
    margin-right: 5px;
  }

  &.first-deposit-col + label span {
    background: url('../img/form-icons.png') top center;
    background-size: 23px;
    background-position: 0 -138px;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    top: 7px;
    margin-right: 5px;
  }

  &.fdc-radio + label span {
    background: url('../img/form-icons.png') top center;
    background-size: 23px;
    background-position: 0 -201px;
    display: inline-block;
    width: 22px;
    height: 19px;
    position: relative;
    top: 7px;
    margin-right: 5px;
  }

  &:checked + label span {
    background-position: 0 -48px;
  }

  &.first-deposit-col:checked + label span {
    background-position: 0 -160px;
  }
  &.fdc-radio:checked + label span {
    background-position: 0 -183px;
  }

  // Red check for deposit modal
  .deposit-bonus-radio &:checked + label span {
    background-position: 0 -96px;
  }
}

input[type='checkbox'] + label span {
  margin: 5px;
}

.deposit-bonus-inner {
  & #fd-col-payments {
    display: grid;
    grid-template-columns: 1fr auto;

    & .arrow-first-deposit-open {
      background: url(../img/icons/form-arrows.png) top center;
      background-size: 23px;
      background-position: 0 24px;
      display: inline-block;
      width: 23px;
      height: 24px;
      position: relative;
      top: 7px;
      margin-right: 5px;
      cursor: pointer;
    }

    & .arrow-first-deposit-close {
      background: url(../img/icons/form-arrows.png) top center;
      background-size: 23px;
      background-position: 0 0px;
      display: inline-block;
      width: 23px;
      height: 24px;
      position: relative;
      top: 7px;
      margin-right: 5px;
      cursor: pointer;
    }
  }

  & .payment-fd-label {
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 550;
    text-align: left;
    text-transform: none;
    margin-left: 30px;
    margin-top: -12px;
    //width: 360px;
  }

  & .change-bonus {
    color: @brand-red;
    cursor: pointer;
  }

  & .tcCBSection {
    color: #9f9f9f;
    font-size: 11px;
    font-weight: normal;
    text-transform: none;
    & .livecasin-cb-uk-tc {
      color: #9f9f9f;
      font-size: 11px;
      font-weight: normal;
      margin-left: 30px;
      text-transform: none;
      display: inline-block;
    }
  }

  & .tcSection {
    color: #9f9f9f;
    font-size: 11px;
    font-weight: normal;
    margin-left: 30px;
    text-transform: none;
  }

  & #first-deposit-container {
    padding-bottom: 10px;
  }

  & .fdc-selection-block {
    display: grid;
    grid-template-columns: 1fr auto;

    & label > span,
    .payment-fd-label {
      cursor: pointer;
    }
  }

  & .first-step {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: var(--tertiary-color);
  }

  & input[type='radio'] {
    & + label span {
      cursor: pointer;
      background-color: transparent !important;
    }
  }
}

div .first-deposit-col-label {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  color: var(--primary-color);
  text-transform: none;
  margin-left: 30px;
  margin-top: -14px;
}

.first-deposit-collection-container {
  & #first-deposit-container {
    padding-bottom: 10px;
  }

  & .step-block {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  & .selection-block {
    display: grid;
    grid-template-columns: 1fr auto;

    & label > span,
    .first-deposit-col-label {
      cursor: pointer;
    }
  }

  & .first-step {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: var(--tertiary-color);
  }
  & .first-collection-line {
    border-top: 1px solid var(--tertiary-color);
    align-self: center;
    margin-left: 10px;
  }

  & .selection-btn {
    display: grid;
    grid-template-columns: auto;
  }

  & .tandctext {
    color: var(--primary-color);
    margin-left: 30px;
    font-size: 10px;
  }
}

.xs5 {
  width: 40%;
  display: inline-block;
}

.xs7 {
  display: inline-block;
  width: 60%;
  padding-left: 16px;
}

.form-space {
  margin: 15px 0px;
}

.arrow-down {
  height: 8px;
  width: 12px;
  background: url('../img/icons/arrow-down.svg') no-repeat top center;
  display: block;
  pointer-events: none;
}

.select-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  .arrow-down {
    position: absolute;
    top: 13px;
    right: 5px;
  }
}

.select-wrap {
  display: inline-block;
  background: transparent;
  width: 100%;
  margin: 0;
  padding: 10px 35px 10px 15px;
  font-size: 16px;
  .rizk-border-radius;
  border: 1px solid #dbdbdb;

  &.no-padding {
    padding: 0;
  }

  &:focus {
    border: 1px solid var(--brand-green);

    & > label {
      color: var(--brand-green);
    }
  }

  &.valid {
    border-color: var(--brand-green);
  }
}

.mudal-bankid-auth {
  input.valid {
    border-color: var(--brand-green);
  }
  input.invalid {
    border-color: @brand-red;
  }
}

@media screen and (max-width: 550px) {
  .pe-second .pe-div-second-group {
    display: flex;
    flex-direction: column;
  }
  .pe-second .pe-div-second-group .help-block {
    margin-top: 5px;
    margin-bottom: 0;
  }
  #second .pe-div-second-group .form-group {
    width: 100%;
    padding-left: 0;
  }

  #second .pe-div-second-group .form-group:nth-child(even) {
    padding-left: 0;
  }

  #second .pe-div-second-group-sex .form-group {
    padding-left: 0;
  }

  #second .pe-div-second-group-sex .form-group label {
    margin-left: 0;
  }
}
.pe-third .accept-terms {
  margin-bottom: 35px !important;
}
.pe-third .form-group label {
  align-items: flex-start !important;
  margin-bottom: 10px;
  line-height: 1.3;
}
.pe-third input[type='checkbox'] + label span {
  margin: 0 5px 5px 5px;
}
