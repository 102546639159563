#payments {
    li {
      &.visa {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/visa.svg') no-repeat;
      }
  
      &.mc {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/mastercard.svg') no-repeat;
      }
  
      &.trustly {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/trustly.svg') no-repeat !important;
      }
  
      &.neteller {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/neteller.svg') no-repeat !important;
      }
  
      &.paysafe {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/paysafe.svg') no-repeat !important;
      }
  
      &.muchbetter {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/much-better.svg') no-repeat !important;
      }

      &.mifinity {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/mifinity.svg') no-repeat !important;
      }

      &.neosurf {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/neosurf.svg') no-repeat !important;
      }

      &.siirto {
        width: 100px;
        height: 50px;
        background: url('/assets/img/devcode/siirto.png') no-repeat !important;
      }

      &.siirto {
        width: 100px;
        height: 50px;
        background: url('/assets/img/footer/optimised/siirto.png') no-repeat !important;
        background-size: contain !important;
        background-position-y: center !important;
      }
    }
  }

  .sr{
    .visa{
      background: url('/assets/img/footer/visa.png') no-repeat !important;
      background-size: contain !important;
      background-position-y:center !important;
      width: 68px !important;
      height: 22px !important;
    }
    .mc{
      background: url('/web/assets/img/footer/optimised/mastercard.png') no-repeat !important;
      background-size: contain !important;
      background-position-y: center !important;
      height: 36px !important;
      width: 20px !important;
    }
  }