// card template css

.card-list {
  width: 100%;
  background-color: grey;
  height: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  h3 {
    font-weight: 100 !important;
    color: black !important;
    text-transform: capitalize !important;
    font-size: 20px !important;
    margin-bottom: 2px !important;
    font-family: "Figtree", sans-serif;
  }
  p {
    font-size: 16px !important;
    color: grey !important;
    font-family: "Figtree", sans-serif;
  }
  li {
    color: #5C5D67;
    span {
      display: block;
      font-family: 'Roboto', sans-serif;;
    }
    .whyrizk-subcontent-container {
      width: 100% !important;
      display: grid;
      margin-bottom: 20px;
      grid-template-columns: .9fr .1fr; 
    }
    .whyrizk-subtitle {
      font-size: 14px;
    }
    .whyrizk-subcontent {
      font-size: 15px;
      font-weight: 600;
      margin-top: 5px;
    }
  }
  .whyrizk-payment-container {
    margin-top: 50px;
    ul {
      text-align: left;
    }
    .plogo {
      width: 70px !important;
      margin: 0 !important;
      object-fit: contain;
      background-size: contain !important;
    }
  }
}

.card-list.vertical {
  display: block;
  flex-direction: column;
}

.card-list.vertical .card {
  width: 480px;
}

.card-list.carousel {
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.card-list.carousel .card {
  width: 200px;
}

.card-list.carousel::-webkit-scrollbar {
  display: none;
}

.card-list.grid {
  flex-direction: row;
  overflow-x: scroll;
  padding-left: 10px;
  padding-right: 10px;
  scrollbar-width: none;
}

.card-list.grid .card {
  width: 100%;
  margin-right: 10px;
  flex-shrink: unset;
}

.card {
  flex-shrink: 0;
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  font-family: 'Roboto', sans-serif;
  scroll-snap-align: start;
  color: black;
  min-height: 250px;
}

h3 {
  margin: 0;
}

p {
  margin-top: 5px;
}


// tooltip styling
.tooltip {
  display: inline-block;
  margin-top: 8px;
  width: 38px !important;
  position: relative;
}

/* Styling the "i" inside the circle */
.tooltip .info-icon {
  display: inline-block;
  width: 20px;
  height: 19px;
  border-radius: 50%;
  background-color: #5C5D67;
  color: white;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;  /* Position above the "i" */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 13px;
}

/* Tooltip arrow */
.tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%; /* Position the arrow at the bottom */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

/* Show the tooltip text when hovering over the circle */
.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}