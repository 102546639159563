.payment-icon(@width,@height, @image, @background-size: contain) {
  width: @width;
  height: @height;
  background-image: url(@image) !important;
  background-size: @background-size;
  background-position: center;
}
// Core variables and mixins
// @import '../../../node_modules/bootstrap/less/variables.less';
// @import '../../../node_modules/bootstrap/less/mixins.less';
@import 'breakpoints';
// Reset and dependencies
//@import "../../../bower_components/bootstrap/less/normalize.less";

// Core CSS
//@import "../../../bower_components/bootstrap/less/scaffolding.less";
//@import "../../../bower_components/bootstrap/less/grid.less";
@import 'forms-override.less';

// Utility classes
//@import "../../../bower_components/bootstrap/less/utilities.less";

@import 'variables';
@import 'bootstrap-override';
@import 'new-mobile-nav';
@import 'lander';
@import 'promotions-home';
@import 'mixins';
@import 'typography';
@import 'loader';
@import 'header';
@import 'footer';
@import 'footer-old';
@import 'footer-old-v2';
@import 'footer-betsson';
@import 'header-logos';
@import 'progressbar';
@import 'modal';
@import 'forms';
@import 'register';
@import 'buttons';
@import 'icons';
@import 'livechat';
@import 'mobile-nav-icons';
@import 'highlight-box';
@import 'notification';
@import 'add-to-homescreen';
@import 'cta';
@import 'panic-bar';
@import 'global';
@import 'popular-links';
@import 'templates';
@import 'homepage-banner';

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

img {
  vertical-align: middle;
}

.clearfix {
  &::after {
    display: block;
    content: '';
    clear: both;
  }
}
.show {
  display: block !important;
}
.hidden {
  display: none !important;
}

html {
  height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  background: var(--secondary-color);
}

.fullwidth {
  width: 100%;
}

// Unordered and Ordered lists
ul,
ol {
  margin: 0;
  list-style: none;
  padding: 0;
}

.invisible {
  visibility: hidden;
}

// Links
a {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

a:focus {
  outline: 0;
  //.tab-focus();
}

section a {
  color: @brand-yellow;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

h1,
h2,
h3,
h4,
button {
  text-transform: uppercase;
  margin: 0;
}

h4 {
  .header-font(28px);
  margin-bottom: 24px;
  color: var(--primary-color);
}

h2 {
  .header-font(38px);
  margin-bottom: 24px;
  color: var(--primary-color);
  letter-spacing: 0;
}

h3 {
  .header-font(32px);
  margin-bottom: 24px;
}

.all-content > section {
  clear: both;
  padding: 44px 128px;
  color: var(--primary-color);
  font-size: 20px;

  button {
    border-radius: var(--button-border-radius);
    margin-top: 32px;
    padding: 12px 48px;
    font-weight: 900;
    color: var(--tertiary-color-button);
    font-size: 20px;
    background-color: var(--tertiary-bg-button);
  }
  button:hover {
    background: var(--tertiary-bg-button-hover);
  }
}

#wheel ul,
#whyrizk ul ul {
  list-style: disc;
  color: var(--tertiary-color);
  padding-left: 20px;
  span {
    color: white;
  }
  li {
    padding-bottom: 12px;
  }
}

.hottestGames {
  &__title {
    .header-font(32px);
    margin-bottom: 34px;
    color: var(--primary-color);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    line-height: 1.1;
    display: inline-block;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    li {
      width: calc(100% / 3 - 7px);
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      a {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        color: white;
        text-align: center;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.tablet({
  .hottestGames {
    ul {
      gap: 20px;
      li {
        width: calc(100% / 5 - 16px);
      }
    }

    .horizontal-toggle & {
      li {
        width: calc(100% / 6 - 17px);
      }
    }
  }
});

#wheel {
  transition: background 1.5s linear;
  position: relative;
  background-size: cover;
  > div {
    width: 50%;
  }

  h3 {
    color: #fff;
  }
  img {
    position: absolute;
    width: 40%;
    min-width: 350px;
    right: -100px;
    top: -50px;
  }
}

// betsson specific styling
#wheelSection {
  display: flex;
  transition: background 1.5s linear;
  background-color: var(--body-background-color);
  justify-content: center;
  flex-direction: row;
  width: 100%;

  .wheelText {
    flex: 1;
    color: var(--primary-color);
    background-color: var(--secondary-body-background-color);
    padding: 2rem;
    border-radius: 5px 0px 0px 5px;
    position: relative;

    h3 {
      .header-font(2rem);
      margin-bottom: 10px;
      color: var(--primary-color);
    }
    h4 {
      .header-font(1.5rem);
    }

    .green-btn {
      .header-font(1.4rem);

      background-color: var(--landing-cta-bg-button);
      color: var(--landing-cta-color-button);
      border: none;
      width: 224px;
      height: 42px;
      padding: 8px 16px;
      border-radius: 4px;
      margin-top: 25px !important;
    }
  }
  .wheelImage {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;

    img {
      max-width: 100%;
      height: calc(100% + 25px);
    }
    .homepageWheel {
      z-index: 2;
      bottom: 0;
      position: absolute;

      // laptop
      @media (min-width: 1151px) and (max-width: 1260px) {
        height: 90%;
      }
    }
    .homepageWheelBackground {
      z-index: 1;
      height: 100%;
      border-radius: 0px 5px 5px 0px;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
}

// tablet
@media (max-width: @menu-switch-breakpoint) {
  #wheelSection {
    flex-direction: column;
    .wheelImage {
      order: 0;
      .homepageWheelBackground {
        border-radius: 10px 10px 0px 0px;
      }
    }
    .wheelText {
      order: 1;
      border-radius: 0px 0px 10px 10px;
      display: grid;
      text-align: center;
    }
    .green-btn {
      justify-self: center;
      position: absolute;
      bottom: -20px;
    }
  }
}
// end of betsson specific styling}

#wheelTitle {
  .header-font(32px);

  color: #fff;
  margin-bottom: 24px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 1.1;
}

#wheelSubTitle {
  .header-font(40px);
  margin-bottom: 24px;
  color: var(--tertiary-color);
  display: block;
  text-transform: uppercase;

  .tablet({
    .font-size(48px);
  });
}
#whyrizk {
  background: var(--secondary-color);
  > div {
    background: var(--tertiary-bg-color);
    > img {
      float: right;
    }
  }
  > ul {
    list-style: none !important;
  }
  ul ul {
    margin-bottom: 90px;
    span {
      color: var(--primary-color);
    }
  }
  ul div {
    width: 50%;
    float: left;
  }
  li {
    clear: both;
  }
  h3 {
    color: var(--tertiary-color);
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 24px;
  }
  ul img {
    width: 250px;
    float: right;
    margin: 0 0 32px 32px;
  }
}

#whyrizk__Title {
  .header-font(40px);

  color: var(--primary-color);
  text-transform: uppercase;
  display: block;
}

#whyrizk__Rewards {
  .header-font(36px);

  color: var(--tertiary-color);
  margin-bottom: 24px;
  text-transform: uppercase;
  display: block;
}

#captain p,
#customers p {
  opacity: 0.8;
  margin: 0;
  padding-bottom: 16px;
  color: var(--primary-color);
}

#captain {
  border-radius: 4px;
  padding: 24px;
  .ib {
    display: inline-block;
    margin-right: 16px;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
  }
}

#customers {
  text-align: center;
  background: var(--tertiary-body-background-color);
  font-size: 18px;
  img {
    float: right;
    margin-right: 48px;
  }
  span {
    vertical-align: middle;
  }
  div p {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
  }
  ul {
    margin: -16px;
    display: flex;
    flex-wrap: wrap;
  }
  li {
    border-radius: 4px;
    flex: 1 1 272px;
    min-width: 272px;
    padding: 24px 24px 64px 24px;
    vertical-align: top;
    //width:312px;
    margin: 16px;
    //padding:16px;
    background: var(--landing-cards-bg);
    text-align: left;
    display: inline-block;
    position: relative;
    div {
      position: absolute;
      bottom: 0;
      height: 64px;
      width: 100%;
    }
  }
}

#customers_reviews {
  color: var(--primary-color);
  font-weight: 800;
  margin-bottom: 24px;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  line-height: 1.1;
  display: block;
}

#lowercta {
  text-align: center;
  background: var(--secondary-bg-color);

  h2 {
    color: var(--primary-color);
    margin: 0 auto;
  }
  button {
    color: var(--landing-cta-color-button);
    background-color: var(--landing-cta-bg-button);
  }
  button:hover {
    background: var(--landing-cta-bg-button-hover);
  }
}

#lowercta_Title {
  color: var(--quaternary-color-button);
  margin: 0 auto;
  font-size: 40px;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: bold;
  display: block;
}

#lc-button {
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 100;
  width: 48px;
  height: 48px;
  background: url('/assets/img/icons/livechatbutton.svg');
  //background-size: cover;
  cursor: pointer;
}

#payments {
  background: white;
  padding: 0 8px;
  text-align: center;
  li {
    vertical-align: middle;
    margin: 20px 20px;
    background: url('/assets/img/lander/payment-logos-3.png') no-repeat;
    background-size: cover;
    display: inline-block;
    height: 40px;
    min-width: 50px;

    &.none {
      min-width: 0;
    }

    &.dina-card {
      background: url('/web/assets/img/lander/dina-card.png') no-repeat;
      width: 60px;
      background-size: contain;
      height: 35px;
    }

    &.bbva {
      background: url('/assets/img/lander/BBVA_2019.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 85px;
    }

    &.bcp {
      background: url('/assets/img/lander/via-bcp-logo-vector.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 85px;
    }

    &.scotiabank {
      background: url('/assets/img/lander/scotiabank-5.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 45px;
    }

    &.pagoefectivo {
      background: url('/assets/img/lander/Pagoefectivo_Paysafe.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 25px;
    }

    &.pagodirecto {
      background: url('/assets/img/lander/PagoDirecto.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 25px;
    }

    &.paysafecard {
      background: url('/assets/img/lander/Paysafecard_logo.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 25px;
    }

    &.interbank {
      background: url('/assets/img/lander/Interbank_logo.svg') no-repeat;
      width: 100px;
      background-size: contain;
      height: 45px;
    }

    &.maestro {
      background-position: -1401px 0;
      width: 124px;
    }

    &.apple {
      width: 70px;
      height: 30px;
      background: url('/assets/img/lander/apple.svg') no-repeat;
    }

    &.euteller {
      width: 80px;
      height: 25px;
      background: url('/assets/img/lander/euteller.png') no-repeat;
      background-size: contain !important;
    }

    &.interac {
      width: 50px;
      height: 50px;
      background: url('/assets/img/lander/interac-2.svg') no-repeat;
      background-size: contain;
    }

    &.interac-e-transfer {
      width: 120px;
      height: 50px;
      background: url('/assets/img/lander/interac-e-transfer.png') no-repeat;
      background-size: contain;
    }

    &.luxonpay {
      width: 134px;
      height: 50px;
      background: url(/assets/img/lander/luxon_pay.svg) no-repeat;
      background-size: cover;
      background-position: center;
    }

    &.poli {
      width: 100px;
      background: url('/assets/img/lander/poli_banner.png') no-repeat;
      background-size: cover;
    }

    &.swish {
      width: 111px;
      background: url('/assets/img/lander/Swish.svg') no-repeat;
    }

    &.rapid {
      width: 80px;
      height: 25px;
      margin-top: 15px;
      background: url('/assets/img/lander/Logo-Rapid-Transfer.svg') no-repeat;
    }

    &.skrill {
      width: 80px;
      height: 25px;
      background: url('/assets/img/lander/skrill.png') no-repeat;
      background-size: contain;
    }

    &.zimpler {
      width: 84px;
      background-position: -456px 0;
    }

    &.sofort {
      width: 88px;
      background-position: -530px 0;
    }

    &.paypal {
      background-position: -858px 0;
      width: 89px;
    }

    &.aircash {
      background-position: -1064px 0;
      width: 111px;
    }
    &.abon {
      background-position: -1176px 0;
      width: 63px;
    }
    &.revolut {
      background-position: -1238px 0;
      width: 83px;
    }

    &.andmore {
      width: 110px;
      height: 79px;
      background: url('/assets/img/lander/andmore.svg') no-repeat;
      margin: 20px;
      margin-top: 30px;
    }

    &.astropay {
      width: 110px;
      background: url('/assets/img/lander/astropay.svg') no-repeat;
      margin-left: 37px;
      background-position: center;
      background-size: contain;
    }

    &.pankki {
      background-position: -755px 0;
      width: 100px;
    }

    &.nis-petrol {
      background: url('/assets/img/lander/nis-petrol.png') no-repeat;
      width: 70px;
      background-size: contain;
      height: 30px;
    }

    &.gazprom {
      background: url('/assets/img/lander/gazprom.png') no-repeat;
      width: 60px;
      background-size: contain;
      height: 35px;
    }

    &.corner-shop {
      background: url('/assets/img/lander/corner-shop.png') no-repeat;
      width: 60px;
      background-size: contain;
      height: 55px;
    }

    &.altapay {
      background: url('/assets/img/lander/altapay.png') no-repeat;
      width: 100px;
      background-size: contain;
      height: 27px;
    }

    &.oktocash {
      background: url('/assets/img/lander/oktocash.png') no-repeat;
      width: 100px;
      background-size: contain;
      height: 27px;
    }
  }
}

@media (max-width: 1200px) {
  #wheel > div {
    width: 60%;
  }
}

@media (max-width: 1000px) {
  #wheel > div {
    width: 75%;
  }

  #payments:not(.in-card) {
    display: none;
  }
  #whyrizk {
    ul img {
      display: none;
    }
    ul div {
      width: 100%;
      float: none;
    }
  }
}

@media (max-width: @menu-switch-breakpoint) {
  #wheel h1,
  h2 {
    .header-font(32px);
  }
}

@media (max-width: 640px) {
  #all-content > section {
    padding: 32px 24px;
  }
  #wheel > div {
    width: 100%;
  }
  #wheel img {
    top: 0;
    opacity: 0.2;
  }
}

@media (max-width: 400px) {
  #customers li {
    width: auto;
  }
}

//Mobile Menu Transitions
.slider-filter {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  transition: 0.3s;
  transition-timing-function: ease-out;
  transform: translateX(-100%);
  &.open {
    background-color: rgba(0, 0, 0, 0.9);
    transform: translateX(0);
  }
}

.sidenavcontainer {
  width: 100vw;
  height: 100vh;
  display: inline-block;
  z-index: 100;
  top: 0;
  left: 0;
  position: fixed;
  transition: 0.4s;
  transition-timing-function: ease-out;
  transform: translateX(-100%);
  &.open {
    transform: translateX(0);
  }
}

.sr {
  &.nis-petrol {
    background: url('/assets/img/lander/nis-petrol.png') no-repeat;
    width: 70px;
    background-size: contain;
    height: 30px;
  }

  &.gazprom {
    background: url('/assets/img/lander/gazprom.png') no-repeat;
    width: 60px;
    background-size: contain;
    height: 35px;
  }

  &.corner-shop {
    background: url('/assets/img/lander/corner-shop.png') no-repeat;
    width: 60px;
    background-size: contain;
    height: 55px;
  }

  &.altapay {
    background: url('/assets/img/lander/altapay.png') no-repeat;
    width: 100px;
    background-size: contain;
    height: 27px;
  }

  &.oktocash {
    background: url('/assets/img/lander/oktocash.png') no-repeat;
    width: 100px;
    background-size: contain;
    height: 27px;
  }
}

.hr {
    .abon {
      .payment-icon(68px,31px,'/web/assets/img/footer/abon.png')!important;
    }
    .visa {
      .payment-icon(60px, 20px, '/web/assets/img/footer/visa.png')!important;
    }
    .mc {
      .payment-icon(14px, 25px, '/web/assets/img/footer/mastercard.png') !important;
    }
    .apple{
      .payment-icon(70px, 32px, '/web/assets/img/devcode/applepay.png') !important;
    }
    .aircash {
      .payment-icon(180px, 21px, '/web/assets/img/footer/aircash.png')!important;
    }
    .revolut {
      .payment-icon(125px, 21px, '/web/assets/img/footer/revolut.png')!important;
    }
  .skrill {
    .payment-icon(80px, 21px, '/assets/img/lander/skrill.png')!important;
  }
  }
